import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics'
import Vue from 'vue'


var firebaseConfig = {
  apiKey: "AIzaSyD0RRt1ciOD-6JZ2RfACtM0FkHc6w4ZXeg",
  authDomain: "appshots-design.firebaseapp.com",
  databaseURL: "https://appshots-design-default-rtdb.firebaseio.com",
  projectId: "appshots-design",
  storageBucket: "appshots-design.appspot.com",
  messagingSenderId: "467823539028",
  appId: "1:467823539028:web:8cc2b9f6f5922a011e0ec1",
  measurementId: "AW-11335389034"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();

// utils
const auth = firebase.auth()

// collection references


// export utils/refs
Vue.mixin({
  data: function () {
    return {
      // db: db,
      auth: auth,
      // appshots: appshots,
      // feedback: feedback,
      // screens: screens,
      // profiles: profiles,
      // storage: storage,
      // analytics: analytics,
    }
  }
})

