
import axios from 'axios';
axios.defaults.withCredentials = true;
import LifetimePopup from '../components/LifetimePopup.vue';
import UpgradePopup from '../components/UpgradePopup.vue';
import MagicSearch from '../components/MagicSearch/MagicSearch.vue';
export default {
  data() {
    return {
      onboardVisible: false,
      showLifetimePopup: false,
      offerParamPresent: false
    }
  },
  created() {
    if (this.$route.query.promo === 'black_friday') {
      this.offerParamPresent = true
    }
    let context = this;
    var url = `${process.env.main_API_URL}/get_configurations/`;
    let referralID = this.$store.state.referralID
    if (referralID && referralID.length > 0) url += `?referral_id=${referralID}`

    axios.get(url).then(function (response) {
      let data = response.data;
      sessionStorage.isAnyCollectionAvailable = data.configurations.has_created_first_collection;
      // Membership
      if (data.membership) {
        context.$store.commit("setAsPro", data.membership == "PRO");
      }
      // Other configurations
      context.$store.commit("setConfigurations", data.configurations);
      context.$store.commit("setDynamicSnackBar", data.dynamic_snack_bar)

      // Set the user authentication data.is_authenticated
      if (data.is_authenticated) {
        // already authenticated
        if (data.configurations) {
          if (data.configurations.survey_completed === false || data.configurations.survey_completed === null) {
            // Code to show onboarding user survey popup goes here
            context.onboardVisible = true
          } else {
            // Survey already completed
          }
        }

      } else {
        context.$store.commit("globalUserDataUpdate", {});
        context.$store.commit("setAuthenticated", false);
      }
    });

    // discount code saving to store
    if (context.$route.query.discount && context.$route.query.discount.length > 0) {
      context.$store.commit('setDiscountCode', context.$route.query.discount);
    }

    setTimeout(() => {
      // Show lifetime popup after 50 seconds, 5 second if promo param present
      context.showLifetimePopup = true
    }, this.offerParamPresent ? 5000 : 50000)
  },
  computed: {
    isLoginPopupVisible() {
      return this.$store.getters.isLoginPopupVisible;
    },
    currentUser() {
      return this.$store.state.globalUserData;
    },
    showUpgradePopup() {
      return this.$store.getters.isUpgradePopupVisible
    },
    showMagicSearch() {
      return this.$store.state.showSearchPopup
    },
  },
  mounted() {
    this.$store.commit('hideLoginPopup')
    this.$store.commit('hideUpgradePopup')
    this.$store.dispatch('enableScroll');
    this.$store.commit('setShowSearchPopup', false);
    window.addEventListener('keydown', this.handleKeydown);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeydown);
  },
  methods: {
    getSearchScollPos() {
      const container = document.querySelector('.magic-search-container');
      const scrollPos = container.scrollTop;
      if (localStorage.getItem('searchLocalData')) {
        // Get and parse existing data
        const searchData = JSON.parse(localStorage.getItem('searchLocalData'));

        // Update the specific property
        searchData.scrollPosition = scrollPos;

        // Save back to localStorage
        localStorage.setItem('searchLocalData', JSON.stringify(searchData));
      }
    },
    closeMagicSearch() {
      this.getSearchScollPos();
      this.$store.dispatch('enableScroll');
      this.$store.commit('setShowSearchPopup', false);
    },
    showLoginPopup() {
      this.$router.push({ query: { redirection_url: encodeURIComponent(window.location.href) } });
      this.$store.commit('showLoginPopup');
      document.body.classList.add('popup-visible');
    },
    handleKeydown(event) {
      if (this.showMagicSearch && event.key === 'Escape') {
        this.closeMagicSearch();
      }
    },
  },
  components: { MagicSearch, LifetimePopup, UpgradePopup }
}
