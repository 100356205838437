
import axios from "axios";
axios.defaults.withCredentials = true;
export default {
  data() {
    return {
      searchQuery: "",
      showLoading: false
    };
  },
  created() {
    // this.loadAllCollectionsGlobal()
  },
  watch: {
    searchQuery: function (val, oldVal) {
      if (val.length > 2) {
        this.updateSearchResults(val);
      } else {
        this.resetSearch();
      }
    },
  },
  methods: {
    addToCollections() {
      let context = this;

      if (context.showLoading) { return }
      var collectionIds = []

      let popup = document.getElementById('select-collection-popup')

      var url = `${process.env.main_API_URL}/add_to_collections/`;
      let data = new FormData()

      let appShotSlugURL = popup.dataset.appShotSlug
      if (appShotSlugURL !== "undefined") {
        data.append('app_shot_slug_url', appShotSlugURL)
      }
      let screenSlugURL = popup.dataset.screenSlug
      if (screenSlugURL !== "undefined") {
        data.append('screen_slug_url', screenSlugURL)
      }

      let screenImgUrl = popup.dataset.screenImgUrl
      if (screenImgUrl !== "undefined") {
        let imgUrl = document.getElementById("screen-pop-image-id").src
        data.append('screen_img_url', imgUrl)
      }


      let selectedCollections = document.querySelectorAll('.aps-form-search-results li input')
      selectedCollections.forEach(function (collection) {
        if (collection.checked) {
          collectionIds.push(collection.dataset.collectionId)
        }
      })
      data.append('collection_ids', collectionIds)


      // Send to server

      // show loader 
      context.showLoading = true

      axios
        .post(url, data)
        .then(function (response) {
          context.showLoading = false
          select_collection_popup_close_button.click()
          context.showToaster('Added to collection successfully', 'success');
        })
        .catch(function (error) {
          context.showLoading = false
          context.showToaster('Something went wrong. Please try again later.', 'error');
        });
    },
    updateSearchResults(query) {
      let context = this;
      var url = `${process.env.main_API_URL}/search_collections/`;
      url = new URL(url);
      url.searchParams.append("q", query);

      axios.get(url.toString()).then(function (response) {
        let data = response.data;
        let responseQuery = data.q;

        if (query !== responseQuery) {
          return;
        }

        context.g_collections = data.collections;
      });
    },
    freshLoadAll() {
      let context = this;
      var url = `${process.env.main_API_URL}/get_all_collections/`;
      url = new URL(url);
      axios.get(url.toString()).then(function (response) {
        let data = response.data;

        context.g_collections = data.collections;

      });
    },
    resetSearch() {
      this.freshLoadAll()
    },
  },
}
