
export default {
    data() {
        return {
            showTopBanner: false,
            isHoveredSpotlight: false,
            timeoutId: null,
            isMobileView: true,
            isSpotLightClicked: false,
            featuredURL: ""
        }
    },
    mounted() {
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'setDynamicSnackBar' || mutation.type === 'setDynamicSnackBarVersion') {
                const currentValue = this.$store.state.dynamicSnackBar && this.$store.state.dynamicSnackBar.spotlight_version || 0
                const lastClosedId = this.$store.state.dynamicSnackBarVersion
                this.showTopBanner = currentValue > lastClosedId && currentValue > 0
                if (this.showTopBanner) {
                    document.body.style.paddingTop = '40px';
                }
                this.featuredURL = this.$store.state.dynamicSnackBar && this.$store.state.dynamicSnackBar.get_featured_url || ""
            }
        });
        const isMobileView = this.mobileCheck()
        this.isMobileView = isMobileView
    },
    computed: {
        getSpotlightIconByType: function () {
            const spotlight_type = this.$store.state.dynamicSnackBar && this.$store.state.dynamicSnackBar.spotlight_type || "" // INFLUENCER | AGENCY | COMPANY
            if (["INFLUENCER", "AGENCY"].includes(spotlight_type)) {
                return "/images/dynamic-snack-bar/spotlightIcon.svg"
            }
            return "/images/dynamic-snack-bar/sponsored_by.svg"
        },
        dynamicBannerStyles: function () {
            const spotlight_bg_color = this.$store.state.dynamicSnackBar && this.$store.state.dynamicSnackBar.spotlight_bg_color || "#FFFFFF" // DYNAMIC SNACK BAR BG
            const bannerStyles = {
                paddingTop: "12px",
                paddingBottom: "12px",
                paddingLeft: "30px",
                paddingRight: "30px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "40px",
            };
            return { ...bannerStyles, backgroundColor: spotlight_bg_color }
        },
        getFeaturedURL: function () {
            const get_featured_url = this.$store.state.dynamicSnackBar && this.$store.state.dynamicSnackBar.get_featured_url || ""
            return get_featured_url
        },
        getFeatureContainerBG: function () {
            const spotlight_bg_color = this.$store.state.dynamicSnackBar && this.$store.state.dynamicSnackBar.spotlight_bg_color || "#FFFFFF" // DYNAMIC SNACK BAR BG
            return { 'background-color': `${this.lightenHexColor(spotlight_bg_color, -20)}` }
        },
        spotlightType: function () {
            const spotlight_type = this.$store.state.dynamicSnackBar && this.$store.state.dynamicSnackBar.spotlight_type || "" // INFLUENCER | AGENCY | COMPANY
            if (this.isMobileView) {
                if (["INFLUENCER", "AGENCY"].includes(spotlight_type)) {
                    return "Spotlight"
                }
                return "Sponsored by"
            }
            return `${spotlight_type.charAt(0) + spotlight_type.slice(1).toLocaleLowerCase()} Spotlight`
        },
        spotlightTitle: function () {
            const spotlight_title = this.$store.state.dynamicSnackBar && this.$store.state.dynamicSnackBar.spotlight_title || "" // Title
            return spotlight_title
        },
        spotlightTextColor: function () {
            const spotlight_text_color = this.$store.state.dynamicSnackBar && this.$store.state.dynamicSnackBar.spotlight_text_color || "#FFFFFF" // DYNAMIC SNACK BAR BG
            return { 'color': spotlight_text_color }
        },
        spotlightImageBG: function () {
            const spotlight_bg_color = this.$store.state.dynamicSnackBar && this.$store.state.dynamicSnackBar.spotlight_bg_color || "#FFFFFF" // DYNAMIC SNACK BAR BG
            return { 'background-color': `${this.lightenHexColor(spotlight_bg_color, 20)}` }
        },
        spotlightImgURL: function () {
            const spotlight_img_url = this.$store.state.dynamicSnackBar && this.$store.state.dynamicSnackBar.spotlight_img_url || "" // IMAGE URL
            return spotlight_img_url
        },
        socialPlatforms: function () {
            const spotlight_connect = this.$store.state.dynamicSnackBar && this.$store.state.dynamicSnackBar.spotlight_connect || {}
            const spotlight_connect_array = Object.entries(spotlight_connect)
                .filter(([key, value]) => value !== "")
                .map(([key, value]) => ({ img_url: `/images/dynamic-snack-bar/${key}.svg`, href: value }));
            return spotlight_connect_array
        },
        externalPageLink: function () {
            const external = this.$store.state.dynamicSnackBar && this.$store.state.dynamicSnackBar.external || {}
            return external
        }
    },
    methods: {
        onClickSpoltLight() {
            if (!this.getFeaturedURL) return
            if (!this.isMobileView) return
            if (this.isSpotLightClicked) {
                this.isSpotLightClicked = false
                this.isHoveredSpotlight = false
            } else {
                this.isSpotLightClicked = true
                this.isHoveredSpotlight = true
            }
        },
        closeBanner() {
            const currentValue = this.$store.state.dynamicSnackBar && this.$store.state.dynamicSnackBar.spotlight_version || 0
            this.$store.commit('setDynamicSnackBarVersion', currentValue)
            document.body.style.paddingTop = 0;
        },
        lightenHexColor(color, percent) {
            if (!color) return "#FFF";
            var num = parseInt(color.replace("#", ""), 16),
                amt = Math.round(2.55 * percent),
                R = (num >> 16) + amt,
                B = ((num >> 8) & 0x00ff) + amt,
                G = (num & 0x0000ff) + amt;
            return (
                "#" +
                (
                    0x1000000 +
                    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
                    (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
                    (G < 255 ? (G < 1 ? 0 : G) : 255)
                )
                    .toString(16)
                    .slice(1)
            );
        },
        hoveredSpotlight(value) {
            if (!this.getFeaturedURL) return
            if (this.isMobileView) return
            if (this.timeoutId) {
                clearTimeout(this.timeoutId)
            }
            this.isHoveredSpotlight = value
        },
        startTimer() {
            this.timeoutId = setTimeout(() => {
                this.hoveredSpotlight(false)
            }, 1000) // 1000 milliseconds = 1 seconds
        },
        clearTimer() {
            clearTimeout(this.timeoutId)
        },
    },
    beforeDestroy() {
        this.clearTimer();
    }
}
