
export default {
    computed: {
        isLoginPopupVisible() {
            return this.$store.getters.isLoginPopupVisible;
        },
    },
    mounted() {
        this.$store.commit('hideLoginPopup');
        this.$store.commit('hideUpgradePopup')
        // discount code saving to store
        if (this.$route.query.discount && this.$route.query.discount.length > 0) {
            this.$store.commit('setDiscountCode', this.$route.query.discount);
        }
    },
}
