export default function ({ app, route }) {
  // create all pages to page name mapping here
  const pageNameMapping = {
    '/': 'HOME',
    '/explore': 'EXPLORE',
    '/apps': 'APP_DETAILS',
    '/about': 'ABOUT',
    '/404': 'PAGE_NOT_FOUND',
    '/accountsettings': 'ACCOUNT_SETTINGS',
    '/advocate-dashboard/': 'ADVOCATE_DASHBOARD',
    '/collections': 'COLLECTIONS',
    '/collection/': 'COLLECTION_DETAILS',
    '/contact': 'CONTACT',
    '/flows/': 'FLOWS',
    '/paymentProcessing': 'PAYMENT_PROCESSING',
    '/pricing': 'PRICING',
    '/PrivacyPolicy': 'PRIVACY_POLICY',
    '/TermsOfService': 'TERMS_OF_SERVICE',
    '/upgradeSuccess': 'UPGRADE_SUCCESS',
  };

  let pageName = 'UNKNOWN_PAGE';  // Default value

  // Check if route.fullPath starts with any key in pageNameMapping
  for (const [key, value] of Object.entries(pageNameMapping)) {
    if (route.fullPath.startsWith(key)) {
      pageName = value;
      break;  // Exit the loop once a match is found
    }
  }

  app.$amplitude.logEvent('PAGE_VIEW', { page: route.fullPath, pageName: pageName });

}