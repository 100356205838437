
export default {
  name: 'OCRMatchComponent',
  props: {
    matchData: {
      type: Object,
      required: true
    },
    noMarker: {
      type: Boolean,
      required: false
    },
    clickFunc: {
      type: Function,
      default: () => { }
    }
  },
  data() {
    return {
      screens: [],
      activeIndex: 0,
      imageLoaded: false,
      windowWidth: window.innerWidth
    }
  },
  computed: {
    getScreen() {
      if (this.matchData && this.matchData.screens && this.matchData.screens.length > 0) {
        return this.matchData.screens[0]
      }
      return {
        resolution_440w: '',
        img_url: '',
        matching_ocr_data: []
      };
    },
    appName() {
      if (typeof this.matchData.app_name === 'undefined') {
        return ''
      }
      return String(this.matchData.app_name)
    },
    isPremiumApp() {
      if (typeof this.matchData === 'undefined') {
        return false
      }
      return Boolean(this.premium_app)
    },
    appDescription() {
      return this.matchData.short_description
    },
    getSlugURL() {
      if (typeof this.matchData === 'undefined') {
        return ''
      }
      const slug = String(this.matchData.appshot_slug)
      return `/apps/${slug}`
    },
  },
  methods: {
    onImageLoad() {
      this.imageLoaded = true;
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    getHighlightStyle(boundingBox) {
      const imageElement = this.$refs.screenImage;
      if (!imageElement) {
        console.log("Image element is not available");
        return {};
      }

      const scaleX = imageElement.clientWidth / this.matchData.screen_width;
      const scaleY = imageElement.clientHeight / this.matchData.screen_height;

      return {
        top: `${(boundingBox.y * scaleY) - 4}px`,
        left: `${(boundingBox.x * scaleX) - 4}px`,
        width: `${(boundingBox.width * scaleX) + 8}px`,
        height: `${(boundingBox.height * scaleY) + 8}px`
      }
    },
    updateScreens() {
      if (typeof this.matchData === 'undefined') {
        this.screens = []
        return
      }
      this.screens = this.matchData.screens
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
      this.$forceUpdate();
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    windowWidth() {
      this.$forceUpdate();
    }
  }
}
