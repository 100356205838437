
import firebase from "firebase/compat/app";
// https://stackoverflow.com/a/54235868
import axios from "axios";
import LoginScreens from "./LoginScreens.vue";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

export default {
  data() {
    return {
      showMoreOptions: false,
      invalidEmail: false,
      emailAuthProgress: false,
      magicLinkSend: false,
      mobileDevice: false,
    }
  },
  mounted() {
    setTimeout(() => {
      this.initializeOneTap();
    }, 1000);
    this.checkDevice();
  },
  methods: {
    closeLoginPopup() {
      this.$store.commit('hideLoginPopup');
      document.body.classList.remove('popup-visible');
    },
    checkDevice() {
      if ((window.innerWidth <= 767)) {
        this.mobileDevice = true
      } else {
        this.mobileDevice = false
      }
    },
    backToLogin() {
      this.magicLinkSend = false
    },
    showMoreToggle() {
      this.showMoreOptions = true
    },
    // One Tap - Google Sign In
    initializeOneTap() {
      if (!window.google || !window.google.accounts || !window.google.accounts.id) {
        return;
      }
      // If user is already signed in, redirect to home page
      if (this.isAuthenticated()) {
        return;
      }
      window.google.accounts.id.initialize({
        client_id: '338437757195-1boft2eoihq7rni726ubc6472t8kafvo.apps.googleusercontent.com',
        callback: this.handleCredentialResponse,
        prompt_parent_id: 'g_id_onload',
      });
      window.google.accounts.id.prompt(); // This will show the One Tap sign-up prompt
    },
    handleCredentialResponse(response) {
      if (!response || !response.credential) {
        return;
      }
      this.$nuxt.$loading.start();
      const googleIdToken = response.credential;
      this.googleOAuthValidate(googleIdToken);
    },
    // UI
    canShowEmailPasswordInput() {
      console.log("canShowEmailPasswordInput");
      // password_id.hidden = validateEmail(this.email_id.value);
    },
    // Auth
    googleAuth() {
      let context = this;
      var provider = new firebase.auth.GoogleAuthProvider();
      provider.setCustomParameters({
        login_hint: "martin@gmail.com",
      });
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          /** @type {firebase.auth.OAuthCredential} */
          var user = result.user;
          context.$nuxt.$loading.start();
          context.getIdTokenAndValidate();
        })
        .catch((error) => {
          // Handle error
        });
    },
    getIdTokenAndValidate() {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          this.firebaseAuthValidate(idToken);
        }.bind(this));
    },
    firebaseAuthValidate(idToken) {
      const url = `${process.env.main_API_URL}/firebase_auth_validate/`;
      const bodyFormData = new FormData();
      bodyFormData.append("id_token", idToken);
      // Send referral ID if available
      let referralID = this.$store.state.referralID;
      if (referralID && referralID.length > 0)
        bodyFormData.append("referral_id", referralID);
      axios({
        method: "post",
        url: url,
        data: bodyFormData,
        headers: {
          "Content-Type": `multipart/form-data; boundary=${bodyFormData._boundary}`,
        },
      }).then((response) => {
        const user = response.data;
        this.proceedToNext(user);
      }).catch((error) => {
        // Handle error
      });
    },
    googleOAuthValidate(idToken) {
      const url = `${process.env.main_API_URL}/validate_google_token/`;
      const bodyFormData = new FormData();
      bodyFormData.append("id_token", idToken);
      // Send referral ID if available
      let referralID = this.$store.state.referralID;
      if (referralID && referralID.length > 0)
        bodyFormData.append("referral_id", referralID);
      axios({
        method: "post",
        url: url,
        data: bodyFormData,
        headers: {
          "Content-Type": `multipart/form-data; boundary=${bodyFormData._boundary}`,
        },
      }).then((response) => {
        const user = response.data;
        this.proceedToNext(user);
      }).catch((error) => {
        // Handle error
      });
    },
    facebookAuth() {
      let context = this;
      var provider = new firebase.auth.FacebookAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          var user = result.user;
          context.$nuxt.$loading.start();
          context.getIdTokenAndValidate();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loginWithDribbble() {
      const clientId = process.env.DRIBBBLE_CLIENT_ID;
      const redirectUri = encodeURIComponent(process.env.DRIBBBLE_REDIRECT_URI);
      const url = `https://dribbble.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code`;
      window.location.href = url;
    },
    twitterAuth() {
      this.$nuxt.$loading.start();
      let context = this;
      var provider = new firebase.auth.TwitterAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          var user = result.user;
          context.$nuxt.$loading.start();
          context.getIdTokenAndValidate();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    appleAuth() {
      let context = this;
      var provider = new firebase.auth.OAuthProvider("apple.com");
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          var user = result.user;
          context.$nuxt.$loading.start();
          context.getIdTokenAndValidate();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    emailAuth() {
      this.emailAuthProgress = true;
      let email = email_id.value;
      // let password = password_id.value;
      // Show toast if invalid
      if (!this.validateEmail(email)) {
        // this.showToaster("You have entered an invalid email address!");
        this.invalidEmail = true;
        this.emailAuthProgress = false;
        return;
      }
      this.invalidEmail = false;
      // if (password_id.style.display == "none") {
      //   password_id.style.display = "block";
      //   return;
      // }
      // this.$nuxt.$loading.start();
      // let context = this;
      // if development then url is local host else appshots.design

      const baseUrl = process.env.NODE_ENV === 'development'
        ? 'http://localhost:3000/emailSignin'
        : 'https://appshots.design/emailSignin';

      firebase
        .auth()
        .sendSignInLinkToEmail(email, {
          url: baseUrl,
          handleCodeInApp: true
        })
        .then(() => {
          window.localStorage.setItem("emailForSignIn", email);
          // context.$nuxt.$loading.start();
          this.emailAuthProgress = false;
          this.magicLinkSend = true;
          // context.$router.push("/check-email");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // Validators
    validateEmail(mail) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true;
      }
      return false;
    },
    // Login flow
    proceedToNext(user) {
      this.$store.commit("globalUserDataUpdate", user);
      this.$store.commit("setAuthenticated", true);
      // All data available
      this.openRedirectionUrl();
    },
    // Sign up for new user.
    emailSignUp() {
      let email = email_id.value;
      let password = password_id.value;
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((data) => {
          this.createNewUserRecord(data.user);
        })
        .catch((err) => {
          this.showToaster(err.message, 'error');
        });
    },
    async createNewUserRecord(user) {
      var userData = {
        id: user.uid,
        type: "profile",
        username: "",
        displayName: user.displayName,
        email: user.email,
        photo_url: user.photo_url,
        user_symbol: "🎃",
      };
      this.$store.commit("globalUserDataUpdate", userData);
      this.$store.commit("setAuthenticated", true);
      let newProfileKey = this.profiles.push().key;
      await this.profiles.child(newProfileKey).set(userData);
      this.openRedirectionUrl();
    },
    openRedirectionUrl() {
      let redirection_url = this.$route.query.redirection_url;

      if (redirection_url) {
        window.location.href = decodeURIComponent(redirection_url);
      } else {
        window.open("/", "_self");
      }
    },
  },
  components: { LoginScreens }
};
