
import axios from "axios";
import SearchInput from "./SearchInput.vue";
import MagicSearchTitle from "./MagicSearchTitle.vue";
import OCRMatchComponent from "./OCRMatchComponent.vue";
import AppPlatforms from "../AppPlatforms.vue";
import UiScreenComponent from "./UiScreenComponent.vue";
import InPagePricingComponent from "../pricing/InPagePricingComponent.vue";
import AppFlowAppShotComponent from "../AppFlowAppShotComponent.vue";

export default {
    name: 'MagicSearch',
    components: { SearchInput, MagicSearchTitle, OCRMatchComponent, AppPlatforms, UiScreenComponent, InPagePricingComponent, AppFlowAppShotComponent },
    data() {
        return {
            query: '',
            results: [],
            selectedPlatform: this.$store.state.selectedPlatform,
            searchMainPage: true,
            flowResults: [],
            appResults: [],
            ocrResults: [],
            contextResults: [],
            uiComponentResults: [],
            screenTagsResults: [],
            debounceTimeout: null,
            searchLoading: false,
            totalResults: 0,
            appflowAppShots: [],
            displayedAppShots: [],
            itemsToShow: 10,
            loadMore: true,
            activeTab: '',
            savedScrollPosition: 0,
            isRestoringResults: false,
            'flowSection': {
                'title': "     ",
                'description': "     ",
                'img_url': '',
                'order': '',
                'slug': '',
            },
            searchCompleted: false,
            membership: this.getMembership(),
        }
    },
    created() {

    },
    mounted() {
        this.$store.dispatch('disableScroll');
        if (this.$refs.tabGlider && this.$refs.tabButtons) {
            this.updateGliderPosition(this.$refs.tabGlider, this.$refs.tabButtons);
        }
        const context = this
        const getLocalSearchData = localStorage.getItem('searchLocalData')
        if (getLocalSearchData) {
            context.isRestoringResults = true
            const state = JSON.parse(getLocalSearchData)
            context.searchMainPage = false
            context.totalResults = state.totalResults
            context.query = state.query
            context.activeTab = state.activeTab
            context.totalResults = state.totalResults
            context.flowResults = state.results.flowResults
            context.appResults = state.results.appResults
            context.ocrResults = state.results.ocrResults
            context.contextResults = state.results.contextResults
            context.uiComponentResults = state.results.uiComponentResults
            context.screenTagsResults = state.results.screenTagsResults
            context.$nextTick(() => {
                context.updateGliderPosition(this.$refs.tabGlider, this.$refs.tabButtons);
                const container = document.querySelector('.magic-search-container');
                // Scroll to position
                container.scrollTo({
                    top: state.scrollPosition,
                });
            })

        }
    },
    computed: {
        showProCTA() {
            // condition to Pricing CTA, currently checking whether the user is pro or not
            return this.membership !== 'PRO'
        },
        selectedTab() {
            // First, check if a tab has been manually selected
            if (this.activeTab) {
                return this.activeTab;
            }

            // If Flows have results, return 'Flows'
            if (this.flowResults.length > 0) {
                return 'Flows';
            }

            // If Screens have more results than Apps, return 'Screens'
            if (this.screenTagsResults.length > this.appResults.length) {
                return 'Screens';
            }

            // If UI Elements have more results than Apps, return 'UI Elements'
            if (this.uiComponentResults.length > this.appResults.length) {
                return 'UI Elements';
            }

            // If Apps have results, return 'Apps'
            if (this.appResults.length > 0) {
                return 'Apps';
            }

            // If Screens have results (but less than or equal to Apps), return 'Screens'
            if (this.screenTagsResults.length > 0) {
                return 'Screens';
            }

            // If UI Elements have results (but less than or equal to Apps), return 'UI Elements'
            if (this.uiComponentResults.length > 0) {
                return 'UI Elements';
            }

            // If Text in Screens have results, return 'Text in Screens'
            if (this.ocrResults.length > 0) {
                return 'Text in Screens';
            }

            // Default to 'Flows' if no results
            return 'Flows';
        },
    },
    methods: {
        getMembership() {
            const currentUser = this.$store.state.globalUserData
            if (!this.isAuthenticated()) {
                return 'GUEST'
            }
            if (currentUser.is_pro_user) {
                return 'PRO'
            }
            return 'BASIC'
        },
        gotoPage(url) {
            this.closeFunction()
            this.$router.push(url)
        },
        getSearchScollPos() {
            const container = document.querySelector('.magic-search-container');
            const scrollPos = container.scrollTop;
            if (localStorage.getItem('searchLocalData')) {
                // Get and parse existing data
                const searchData = JSON.parse(localStorage.getItem('searchLocalData'));

                // Update the specific property
                searchData.scrollPosition = scrollPos;

                // Save back to localStorage
                localStorage.setItem('searchLocalData', JSON.stringify(searchData));
            }
        },
        closeFunction() {
            this.getSearchScollPos()
            this.$store.commit('setShowSearchPopup', false)
            this.$store.dispatch('enableScroll')
        },
        handleLinkClick() {
            this.getSearchScollPos()
            this.$store.commit('setShowSearchPopup', false)
            this.$store.dispatch('enableScroll')
        },
        showLoginPopup() {
            this.$router.push({ query: { redirection_url: encodeURIComponent(window.location.href) } });
            this.$store.commit('showLoginPopup')
            document.body.classList.add('popup-visible')
        },
        loadMoreAppShots() {
            if (!this.isAuthenticated()) {
                this.showToaster('Please login to continue', 'error')
                this.$store.commit('setLoginPopupTitle', 'Join Appshots')
                this.showLoginPopup();
                return
            }
            if (this.displayedAppShots.length < this.appflowAppShots.length) {
                this.loadMore = true;
                this.itemsToShow += 10;
                this.displayedAppShots = this.appflowAppShots.slice(0, this.itemsToShow);
            } else {
                this.loadMore = false;
            }
        },
        debouncedSearch() {
            clearTimeout(this.debounceTimeout)
            this.debounceTimeout = setTimeout(() => {
                //  this.updateURL()
                this.performSearch()

            }, 500) // Adjust debounce delay as needed
        },
        async performSearch() {
            const context = this;
            context.searchCompleted = false;
            context.activeTab = '';
            context.appResults = []
            context.ocrResults = []
            context.contextResults = []
            context.uiComponentResults = []
            context.screenTagsResults = []
            context.flowResults = []
            context.totalResults = 0
            context.appflowAppShots = []
            context.displayedAppShots = []
            context.itemsToShow = 10
            context.loadMore = false
            if (context.query.length) {
                context.searchMainPage = false;
                context.searchLoading = true;
                context.loadMore = true;
            } else {
                this.searchMainPage = true;
                context.searchLoading = false;
                return;
            }
            try {
                await Promise.all([
                    context.searchFlows(),
                    context.searchApps(),
                    context.searchOCR(),
                    context.searchContext(),
                    context.searchUIComponents(),
                    context.searchScreenTags(),
                ]);
            } finally {
                context.totalResults = context.appResults.length + context.ocrResults.length + context.contextResults.length + context.uiComponentResults.length + context.screenTagsResults.length + context.flowResults.length;
                const searchLocalData = {
                    query: this.query,
                    selectedPlatform: this.selectedPlatform,
                    activeTab: this.activeTab || this.selectedTab,
                    scrollPosition: window.scrollY,
                    results: {
                        flowResults: this.flowResults,
                        appResults: this.appResults,
                        ocrResults: this.ocrResults,
                        contextResults: this.contextResults,
                        uiComponentResults: this.uiComponentResults,
                        screenTagsResults: this.screenTagsResults
                    },
                    totalResults: this.totalResults,
                    searchCompleted: true,
                    timestamp: Date.now()
                }
                localStorage.setItem('searchLocalData', JSON.stringify(searchLocalData))
                context.searchLoading = false;
                context.searchCompleted = true;
                setTimeout(() => {
                    if (context.$refs.tabGlider && context.$refs.tabButtons) {
                        context.updateGliderPosition(context.$refs.tabGlider, context.$refs.tabButtons);
                    }
                }, 100);
            }
        },
        async searchApps() {
            try {
                const url = `${process.env.main_API_URL}/search_app_shots_v2/`
                const response = await this.$axios.get(url, {
                    progress: false,
                    params: { q: this.query, platform: this.selectedPlatform }
                })
                this.appResults = response.data.app_shots || []
            } catch (error) {
                console.error('Error fetching flow results:', error)

            }
        },
        async searchOCR() {
            try {
                const url = `${process.env.main_API_URL}/search_text_in_images/`
                const response = await this.$axios.get(url, {
                    progress: false,
                    params: { q: this.query, platform: this.selectedPlatform }
                })
                this.ocrResults = response.data.results || []

            } catch (error) {
                console.error('Error fetching OCR results:', error)

            }
        },
        async searchContext() {
            try {
                const url = `${process.env.main_API_URL}/search_context_in_images/`
                const response = await this.$axios.get(url, {
                    progress: false,
                    params: { q: this.query, platform: this.selectedPlatform }
                })
                this.contextResults = response.data.results || []
            } catch (error) {
                console.error('Error fetching context results:', error)
            }
        },
        async searchFlows() {
            try {
                const url = `${process.env.main_API_URL}/search_flows/`
                const response = await this.$axios.get(url, {
                    progress: false,
                    params: { q: this.query, platform: this.selectedPlatform }
                })
                if (this.membership !== 'PRO') {
                    this.flowResults = Array.isArray(response.data.appflow_appShots) && response.data.appflow_appShots.length > 0
                        ? [response.data.appflow_appShots[0]]
                        : [];
                } else {
                    this.flowResults = response.data.appflow_appShots || []
                }
            } catch (error) {
                console.error('Error fetching context results:', error)
            }
        },

        async searchUIComponents() {
            try {
                const url = `${process.env.main_API_URL}/search_screen_ui_component/`
                const response = await this.$axios.get(url, {
                    progress: false,
                    params: { q: this.query, platform: this.selectedPlatform }
                })
                this.uiComponentResults = response.data.screens || []
            } catch (error) {
                console.error('Error fetching uiComponent results:', error)
            }
        },
        async searchScreenTags() {
            try {
                const url = `${process.env.main_API_URL}/search_screen_tags/`
                const response = await this.$axios.get(url, {
                    progress: false,
                    params: { q: this.query, platform: this.selectedPlatform }
                })
                this.screenTagsResults = response.data.screens || []
            } catch (error) {
                console.error('Error fetching screenTags results:', error)
            }
        },
        getAppflowDetails(appflow_key) {
            const context = this;
            axios.get(`${process.env.main_API_URL}/get_flow_details/`, {
                params: {
                    appflow_id: appflow_key || 5,
                    platform: this.selectedPlatform,
                    // categories: context.$store.state.selectedCategories.join(',')
                }
            }).then(response => {
                context.section = response.data.section;

                const filteredAppShots = response.data.appflow_appShots.filter(appShot =>
                    appShot.screens.some(screen =>
                        screen.platform.name.toLowerCase() === context.selectedPlatform.toLocaleLowerCase()
                    )
                );
                context.appflowAppShots = filteredAppShots;
            }).catch(error => {
                console.log(error)
            })
        },
        mapOCRData(data) {
            return {
                id: data.screen_id,
                slug: data.screen_slug,
                app_name: data.appshot_app_name,
                appshot_slug: data.appshot_slug,
                short_description: data.appshot_short_description,
                screen_count: 1,
                screens: [
                    {
                        id: data.screen_id,
                        img_url: data.img_url,
                        resolution_440w: data.img_url,
                        app_shot: {
                            app_name: data.appshot_app_name,
                        },
                        matching_ocr_data: data.matching_ocr_data
                    }
                ],
                app_version: data.app_version,
                app_key: data.appshot_app_key,
                description: data.description,
                section: data.section,
                section_slug: data.section_slug,
                score: data.score,
                app_icon: data.app_icon,
                screen_width: data.screen_width,
                screen_height: data.screen_height,
            }
        },
        mapScreenData(data) {
            return {
                id: data.id,
                slug: data.slug,
                app_name: data.app_shot.app_name,
                appshot_slug: data.app_shot.slug,
                short_description: data.app_shot.short_description,
                screen_count: 1,
                screens: [
                    {
                        id: data.id,
                        img_url: data.img_url,
                        resolution_440w: data.resolution_440w || data.img_url,
                        app_shot: {
                            app_name: data.app_shot.app_name,
                        },
                        matching_ocr_data: data.ocr_text
                    }
                ],
                app_key: data.app_shot.slug,
                description: data.meta_description || '',
                app_icon: data.app_shot.app_icon,
            }
        },
        mapContextMatchToAppShot(contextMatch) {
            return {
                id: contextMatch.id,
                slug: contextMatch.slug,
                app_name: contextMatch.app_shot.app_name,
                app_icon: contextMatch.app_shot.app_icon,
                short_description: contextMatch.app_shot.short_description,
                screens: [
                    {
                        id: contextMatch.id,
                        img_url: contextMatch.img_url,
                        resolution_220w: contextMatch.resolution_220w,
                        resolution_440w: contextMatch.resolution_440w,
                        resolution_1080w: contextMatch.resolution_1080w,
                        order: contextMatch.order,
                        platform: contextMatch.platform
                    }
                ]
            }
        },
        selectPlatform(platform) {
            this.selectedPlatform = platform;
            this.$store.commit("setSelectedPlatform", platform);
            this.performSearch();
        },
        selectTab(tab) {
            this.activeTab = tab;
            if (this.$refs.tabGlider && this.$refs.tabButtons) {
                this.updateGliderPosition(this.$refs.tabGlider, this.$refs.tabButtons);
            }
            if (localStorage.getItem('searchLocalData')) {
                // Get and parse existing data
                const searchData = JSON.parse(localStorage.getItem('searchLocalData'));

                // Update the specific property
                searchData.activeTab = tab;

                // Save back to localStorage
                localStorage.setItem('searchLocalData', JSON.stringify(searchData));
            }
        },
        updateGliderPosition(glider, buttons) {
            if (!glider || !buttons) return;
            const tabButtonsDiv = buttons;
            const alButtons = tabButtonsDiv.querySelectorAll('button');
            this.$nextTick(() => {
                const activeButton = Array.from(alButtons).find(btn => btn.classList.contains('active'));
                if (activeButton) {
                    const rect = activeButton.getBoundingClientRect();
                    const rectLeft = activeButton.offsetLeft;
                    glider.style.width = `${rect.width}px`;
                    glider.style.transform = `translateX(${rectLeft}px)`;
                }
            });
        },
    },
}
