
import axios from "axios";
axios.defaults.withCredentials = true;

export default {
  props: {
    appShot: Object,
    platform_name: String,
    activeItem: Boolean,
    clickFunc: {
      type: Function,
      default: () => { }
    },
    linkClick: {
      type: Function,
      default: () => { }
    }
  },
  data() {
    return {
      screens: [],
      activeIndex: 0,
    };
  },
  created() {
    if (typeof this.appShot === "undefined") {
      return;
    }
  },
  watch: {
    appShot: {
      handler: "updateScreens",
      immediate: true
    }
  },
  methods: {
    updateScreens() {
      if (typeof this.appShot === "undefined") {
        this.screens = [];
        return;
      }
      let context = this;
      context.screens = [];
      const screen = this.appShot.screens[0];
      if (screen) {
        context.screens.push(screen);
      }
    },
    openExternalLink() {
      // Open external link
      var url = this.appShot["promoted_app_url"];
      if (url) {
        window.open(this.addRefToUrl(url), "_blank");
      }

      // Log click
      let slug = this.appShot["slug"];
      if (slug) {
        let url = `${process.env.main_API_URL}/log_promotion_link_click/`;
        axios.post(url);
      }
    }
  },
  computed: {
    appName() {
      if (typeof this.appShot === "undefined") {
        return "";
      }
      return String(this.appShot["app_name"]);
    },
    isPremiumApp() {
      if (typeof this.appShot === "undefined") {
        return false;
      }
      return Boolean(this.appShot["premium_app"]);
    },
    appPromoted() {
      if (typeof this.appShot === "undefined") {
        return false;
      }
      return Boolean(this.appShot["promoted_app"]);
    },
    hasExternalLink() {
      if (typeof this.appShot === "undefined") {
        return false;
      }
      return Boolean(this.appShot["promoted_app_url"] && this.appShot["promoted_app_url"] !== "");
    },
    appCountrySymbol() {
      if (typeof this.appShot === "undefined") {
        return "";
      }
      return String(this.appShot["country_symbol"]);
    },
    appDescription() {
      if (typeof this.appShot === "undefined") {
        return "";
      }
      return String(this.appShot["short_description"]);
    },
    appIcon() {
      if (typeof this.appShot === "undefined") {
        return "";
      }
      return String(this.appShot["app_icon"]);
    },
    getAppShotURL() {
      if (typeof this.appShot === "undefined") {
        return "";
      }
      let slug = String(this.appShot["slug"]);
      return `/apps/${slug}/`;
    },
    getSlugURL() {
      if (typeof this.appShot === "undefined") {
        return "";
      }
      let slug = String(this.appShot["slug"]);
      return slug;
    },
    enableAddToCollectionCTA() {
      if (typeof this.appShot === "undefined") {
        return false;
      }

      if (!this.isAuthenticated()) {
        return false;
      }

      return true
    },
  },
};
