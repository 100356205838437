// userLogger.js

import axios from 'axios';

class UserAction {
  constructor(dataType, dataSubType, data) {
    this.dataType = dataType;
    this.dataSubType = dataSubType;
    this.data = data;
  }
}

class AppBrowsingAction extends UserAction {
  constructor(dataSubType, data) {
    super('app_browsing', dataSubType, data);
  }
}

class EmailAction extends UserAction {
  constructor(dataSubType, data) {
    super('email_action', dataSubType, data);
  }
}

class ExternalAction extends UserAction {
  constructor(dataSubType, data) {
    super('external_action', dataSubType, data);
  }
}

const logUserAction = async (action) => {
  try {
    const response = await axios.post(`${process.env.main_API_URL}/log_user_data/`, {
      data_type: action.dataType,
      data_sub_type: action.dataSubType,
      data: action.data
    });
    console.log('User action logged successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error logging user action:', error);
    throw error;
  }
};

// Helper methods for common actions
const logAppBrowsing = (subType, data) => {
  const action = new AppBrowsingAction(subType, data);
  return logUserAction(action);
};

const logEmailAction = (subType, data) => {
  const action = new EmailAction(subType, data);
  return logUserAction(action);
};

const logExternalAction = (subType, data) => {
  const action = new ExternalAction(subType, data);
  return logUserAction(action);
};

// Specific logging functions
const logPricingPageVisit = (planId, checkoutAttempt = false) => {
  return logAppBrowsing('pricing_page_visit', { plan_id: planId, checkout_attempt: checkoutAttempt });
};

const logHomePageVisit = (platform, category, flowId) => {
  return logAppBrowsing('home_page_visit', { platform, category, flow_id: flowId });
};

const logAppDetails = (appName, slug, flowId) => {
  return logAppBrowsing('app_details', { app_name: appName, slug, flow_id: flowId });
};

const logPromoDiscount = (campaignId, emailId, recipientId) => {
  return logEmailAction('promo_discount', { campaign_id: campaignId, email_id: emailId, recipient_id: recipientId });
};

const logNewsletter = (campaignId, emailId, recipientId) => {
  return logEmailAction('newsletter', { campaign_id: campaignId, email_id: emailId, recipient_id: recipientId });
};

const logAdAction = (campaignId, source, medium, content, term) => {
  return logExternalAction('ad_action', { 
    campaign_id: campaignId, 
    source, 
    medium, 
    content, 
    term 
  });
};

const logExternalLinkClick = (data) => {
  return logExternalAction('external_link_click', data);
};

export {
  logUserAction,
  logAppBrowsing,
  logEmailAction,
  logExternalAction,
  // Specific logging functions
  logPricingPageVisit,
  logHomePageVisit,
  logAppDetails,
  logPromoDiscount,
  logNewsletter,
  logAdAction,
  logExternalLinkClick
};