// plugins/tracking.js
import { logPromoDiscount, logNewsletter, logAdAction, logExternalLinkClick } from '@/utils/userLogger';

export default ({ app, route }) => {
  app.router.afterEach((to, from) => {
    const query = { ...to.query };
    let shouldUpdateQuery = false;

    // Extract UTM parameters
    const utmSource = query.utm_source;
    const utmMedium = query.utm_medium;
    const utmCampaign = query.utm_campaign;
    const utmContent = query.utm_content;
    const utmTerm = query.utm_term;

    // Extract email-specific parameters
    const emailId = query.email_id;
    const recipientId = query.recipient_id;

    // Log based on UTM parameters
    if (utmSource) {
      let actionData = {
        source: utmSource,
        medium: utmMedium,
        campaign: utmCampaign,
        content: utmContent,
        term: utmTerm,
        email_id: emailId,
        recipient_id: recipientId
      };

      // Remove undefined values
      Object.keys(actionData).forEach(key => actionData[key] === undefined && delete actionData[key]);

      // Use setTimeout to make logging asynchronous
      setTimeout(() => {
        if (utmMedium === 'email') {
          if (utmSource === 'newsletter') {
            logNewsletter(utmCampaign, emailId, recipientId)
              .catch(error => console.error('Failed to log newsletter:', error));
          } else {
            logPromoDiscount(utmCampaign, emailId, recipientId)
              .catch(error => console.error('Failed to log promo email:', error));
          }
        } else if (utmMedium === 'cpc' || utmMedium === 'display') {
          logAdAction(utmCampaign, utmSource, utmMedium, utmContent, utmTerm)
            .catch(error => console.error('Failed to log ad click:', error));
        } else {
          logExternalLinkClick(actionData)
            .catch(error => console.error('Failed to log external action:', error));
        }
      }, 0);

      // Remove UTM and email parameters from URL
      ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term', 'email_id', 'recipient_id']
        .forEach(param => {
          if (query[param]) {
            delete query[param];
            shouldUpdateQuery = true;
          }
        });
    }

    // If we've removed any parameters, update the URL
    if (shouldUpdateQuery) {
      app.router.replace({ query });
    }
  });
};