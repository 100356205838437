export const state = () => ({
  globalUserData: {
    id: "",
    type: "profile",
    username: Math.random().toString(36).substring(7),
    email: "",
    display_name: "",
    role: "",
    purpose: "",
    referred_from: "",
    photo_url: "/images/user.png",
    is_pro_user: false,
    user_symbol_bg: "#FFE27B",
  },
  loginPopupVisible: false,
  loginPopupTitle: "Welcome Back",
  siteTheme: 'system',
  discountCode: "",
  isAuthenticated: false,
  stopPagination: false,
  selectedPlatform: "ios",
  selectedCategories: [],
  reloadPage: true,
  showSearchPopup: false,
  showIntroCaurosel: true,
  showSurveyPopup: true,
  closedTopBannerId: 0,
  configurations: {
    top_slider_config: {},
    top_banner_config: {
      bannerStyles: {
        paddingTop: "12px",
        paddingBottom: "12px",
        paddingLeft: "30px",
        paddingRight: "30px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "40px",
      },
    },
  },
  dynamicSnackBar: {},
  dynamicSnackBarVersion: 0,
  upvotedAppShots: [],
  appshotSubscribeSlug: "",
  referralID: null,
  pageData: {},
  lifeTimeOfferShown: false,
  musicPlaying: false,
  activeMusic: 0,
  upgradePopupVisible: false,
  isScrollDisabled: false,
  scrollPosition: 0,
  initCategoryCarousel: false,
  initFlowCarousel: false,
});

export const mutations = {
  globalUserDataUpdate(state, newData) {
    if (
      newData.photo_url == null ||
      newData.photo_url == undefined ||
      newData.photo_url === ""
    ) {
      newData.photo_url = "/images/user.png";
    }
    state.globalUserData = newData;
  },
  updateDisplayName(state, newDisplayName) {
    state.globalUserData.display_name = newDisplayName;
  },
  updateUserRole(state, newRole) {
    state.globalUserData.role = newRole;
  },
  updatePurpose(state, newPurpose) {
    state.globalUserData.purpose = newPurpose;
  },
  updateRefferedFrom(state, newReferral) {
    state.globalUserData.referred_from = newReferral;
  },
  // Mutation to show the login popup
  showLoginPopup(state) {
    state.loginPopupVisible = true;
  },

  // Mutation to hide the login popup
  hideLoginPopup(state) {
    state.loginPopupVisible = false;
  },
  showUpgradePopup(state) {
    state.upgradePopupVisible = true;
  },
  hideUpgradePopup(state) {
    state.upgradePopupVisible = false;
  },
  // Mutation to change site theme
  setSiteThemeLight(state) {
    state.siteTheme = 'light';
  },
  setSiteThemeDark(state) {
    state.siteTheme = 'dark';
  },
  setSiteThemeSystem(state) {
    state.siteTheme = 'system';
  },
  setAuthenticated(state, newData) {
    state.isAuthenticated = newData;
  },
  setAsPro(state, newData) {
    state.globalUserData.is_pro_user = newData;
  },
  setSelectedPlatform(state, newData) {
    state.selectedPlatform = newData;
  },
  setSelectedCategories(state, newData) {
    state.selectedCategories = newData;
  },
  setStopPagination(state, newData) {
    state.stopPagination = newData;
  },
  setReloadPage(state) {
    state.reloadPage = !state.reloadPage;
  },
  setShowSearchPopup(state, newData) {
    state.showSearchPopup = newData;
  },
  setClosedTopBannerId(state, newData) {
    state.closedTopBannerId = newData;
  },
  setConfigurations(state, newData) {
    state.configurations = newData;
  },
  setDynamicSnackBar(state, newData) {
    state.dynamicSnackBar = newData;
  },
  setDynamicSnackBarVersion(state, newData) {
    state.dynamicSnackBarVersion = newData;
  },
  setHideIntroCaurosel(state, newData) {
    state.showIntroCaurosel = newData;
  },
  setHideSurveyPopup(state, newData) {
    state.showSurveyPopup = newData;
  },
  addSelectedUpvotedAppShots(state, newData) {
    state.upvotedAppShots.push(newData);
  },
  removeSelectedUpvotedAppShots(state, newData) {
    state.upvotedAppShots = newData;
  },
  setAppshotSubscribeSlug(state, newData) {
    state.appshotSubscribeSlug = newData;
  },
  setReferralID(state, newData) {
    state.referralID = newData;
  },
  setPageData(state, newData) {
    state.pageData = newData;
  },
  setLoginPopupTitle(state, newData) {
    state.loginPopupTitle = newData;
  },
  setDiscountCode(state, newData) {
    state.discountCode = newData;
  },
  setMusicPlaying(state, newData) {
    state.musicPlaying = newData;
  },
  setActiveMusic(state, newData) {
    state.activeMusic = newData;
  },
  setLifeTimeOfferShown(state, newData) {
    state.lifeTimeOfferShown = newData
  },
  SET_SCROLL_DISABLED(state, isDisabled) {
    state.isScrollDisabled = isDisabled
  },
  SET_SCROLL_POSITION(state, position) {
    state.scrollPosition = position
  },
  setInitCategoryCarousel(state, newData) {
    state.initCategoryCarousel = newData;
  },
  setInitFlowCarousel(state, newData) {
    state.initFlowCarousel = newData;
  },
};

export const getters = {
  isLoginPopupVisible: state => state.loginPopupVisible,
  getLoginPopupTitle: state => state.loginPopupTitle,
  getSiteTheme: state => state.siteTheme,
  isUpgradePopupVisible: state => state.upgradePopupVisible,
};

export const actions = {
  disableScroll({ commit, state }) {
    if (process.client && !state.isScrollDisabled) {
      const scrollPosition = window.scrollY || document.documentElement.scrollTop || 0;
      commit('SET_SCROLL_POSITION', scrollPosition)
      document.body.style.position = 'fixed'
      document.body.style.top = `-${scrollPosition}px`
      document.body.style.width = '100%'
      document.body.style.overflowY = 'scroll'  // Prevent layout shift
      commit('SET_SCROLL_DISABLED', true)
    }
  },
  enableScroll({ commit, state }) {
    if (process.client && state.isScrollDisabled) {
      document.body.style.position = ''
      document.body.style.top = ''
      document.body.style.width = ''
      document.body.style.overflowY = ''
      window.scrollTo(0, state.scrollPosition)
      commit('SET_SCROLL_DISABLED', false)
    }
  }
}