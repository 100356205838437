
export default {
    methods: {
        handleClick() {
            this.$store.commit("setShowSearchPopup", true);
        },
    },
    mounted() {
        document.addEventListener("keydown", (event) => {
            // Don't trigger if user is typing in an input or textarea
            if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
                return;
            }

            // Check for forward slash key
            if (event.key === '/' || event.key === 'Slash') {
                event.preventDefault();
                this.handleClick();
            }
        });
    },
    beforeDestroy() {
        document.removeEventListener("keydown", this.handleKeyDown);
    },
};
