import { render, staticRenderFns } from "./LandingFooter.vue?vue&type=template&id=5b2fe82d&scoped=true"
var script = {}
import style0 from "./LandingFooter.vue?vue&type=style&index=0&id=5b2fe82d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b2fe82d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterLinks: require('/home/runner/work/AppShotsWebapp/AppShotsWebapp/components/FooterLinks.vue').default})
