
export default {
    props: {
        videoId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            playerId: `player-${this._uid}`,
            player: null,
            isMuted: true,
            isPlaying: false,
            isReady: false
        };
    },
    mounted() {
        // Load YouTube Iframe API script if not already loaded
        if (!window.YT) {
            const script = document.createElement('script');
            script.src = 'https://www.youtube.com/iframe_api';
            document.body.appendChild(script);
        }

        // Ensure the API is ready before creating the player
        if (window.YT && typeof window.YT.Player === 'function') {
            this.createPlayer();
        } else {
            // Listen for API ready event
            window.onYouTubeIframeAPIReady = () => {
                this.createPlayer();
            };
        }
    },
    methods: {
        createPlayer() {
            this.player = new window.YT.Player(this.playerId, {
                height: '0',
                width: '0',
                videoId: this.videoId,
                playerVars: {
                    mute: 1
                },
                events: {
                    onReady: this.onPlayerReady,
                    onStateChange: this.onPlayerStateChange
                }
            });
        },
        onPlayerReady(event) {
            event.target.playVideo();
            this.isPlaying = true;
            this.isReady = true;
            event.target.unMute();
            this.isMuted = false
        },
        onPlayerStateChange(event) {
            if (event.data === window.YT.PlayerState.ENDED) {
                this.player.playVideo(); // Loop the video when it ends
            }
        },
        toggleMute() {
            if (this.isReady) {
                if (this.isMuted) {
                    this.player.unMute();
                    this.player.playVideo();
                } else {
                    this.player.mute();
                    this.player.pauseVideo();
                }
                this.isMuted = !this.isMuted;
            }
        }
    },
    beforeDestroy() {
        if (this.player) {
            this.player.destroy();
        }
    }
};
