
import axios from 'axios'
import JSZip, { filter } from 'jszip';
import { saveAs } from 'file-saver';
export default {
    props: {
        appName: String,
        flowTitle: String,
        filterId: String,
        tabType: String,
        slugUrl: String,
        className: {
            type: [String, Array],
            default: () => []
        }
    },
    data() {
        return {
            zipStatus: '',
            currentPlatform: this.$store.state.selectedPlatform,
            screensData: [],
            screensLoaded: false
        }
    },
    computed: {
        platformName() {
            const platform = this.$store.state.selectedPlatform;
            if (platform === 'android' || platform === 'web') {
                return platform.charAt(0).toUpperCase() + platform.slice(1);
            } else if (platform === 'ios') {
                return 'iOS';
            } else {
                return platform; // Return the original platform name if it's none of the above
            }
        },
        currentUser() {
            return this.$store.state.globalUserData;
        },
    },
    methods: {
        showLoginPopup() {
            this.$router.push({ query: { redirection_url: encodeURIComponent(window.location.href) } });
            this.$store.commit('setLoginPopupTitle', 'Join Appshots')
            this.$store.commit('showLoginPopup');
            document.body.classList.add('popup-visible');
        },
        fetchAllScreens(app, flow) {
            const context = this
            let url = `${process.env.main_API_URL}/get_appshot_screens_filtered/`
            url = new URL(url)
            url.searchParams.append('slug', context.slugUrl)
            url.searchParams.append('platform', context.currentPlatform)
            url.searchParams.append('filter_type', context.tabType)
            url.searchParams.append('per_page', 9999) // get all

            url.searchParams.append('filter_id', context.filterId)

            axios.get(url).then(response => {
                context.screensData = response.data.screens;
                context.downloadImagesAsZip(app, flow);
            }).catch(error => {
                console.error('Error fetching screens:', error);
            });
        },
        async downloadImagesAsZip(app, flow) {
            const context = this;
            if (!context.isAuthenticated()) {
                context.showLoginPopup();
                return;
            }
            if (context.currentUser.is_pro_user === false) {
                context.$store.commit('showUpgradePopup');
                return;
            }
            context.zipStatus = 'processing'; // Set zipStatus to 'processing' at the start
            const zip = new JSZip();
            const imgFolder = zip.folder(app + ' ' + flow + ' ' + context.platformName + ' from Appshots');
            const imagePromises = context.screensData.map(async (screen, index) => {
                try {
                    const response = await fetch(`${process.env.main_API_URL}/screenshot/?slug=${screen.slug}`);
                    if (!response.ok) throw new Error('Failed to fetch image');
                    const blob = await response.blob();
                    imgFolder.file(`${app} ${flow} ${context.platformName} ${index + 1}.jpg`, blob, { binary: true });
                } catch (error) {
                    console.error(`Failed to fetch image at ${screen.img_url}: ${error}`);
                    context.zipStatus = 'error'; // Change zipStatus to 'error' if fetching fails
                    context.showToaster('Oops! There seems some issues, please try again.', 'error');
                    throw error; // Rethrow the error to stop processing further images
                }
            });
            try {
                await Promise.all(imagePromises);
                zip.generateAsync({ type: "blob" }).then((content) => {
                    saveAs(content, app + ' for ' + context.platformName + ' - ' + flow + ' Flows from Appshots.zip');
                    context.zipStatus = 'done'; // Set zipStatus to 'done' once the zip is generated and saved
                    context.showToaster('Downloaded Successfully', 'success');
                });
            } catch (error) {
                console.log('error', error)
                // Handle the error or failed state here if needed
                // zipStatus is already set to 'error' in the catch block above
            }
        },
    }
}
