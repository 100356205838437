
export default {
    name: 'cookieconsent',
    data() {
        return {
            cookieAccepted: false,
            cookieLoaded: false
        };
    },
    mounted() {
        setTimeout(() => {
            this.cookieAccepted = this.getCookie('cookie_consent') === 'accepted';
            this.cookieLoaded = true
        }, 2000);
    },
    methods: {
        acceptCookies() {
            this.setCookie('cookie_consent', 'accepted', 365);
            this.cookieAccepted = true;
        },
        rejectCookies() {
            this.setCookie('cookie_consent', 'rejected', 365);
            this.cookieAccepted = true; // You may want to adjust this behavior based on your needs
        },
        setCookie(name, value, days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            const expires = "expires=" + date.toUTCString();
            document.cookie = name + "=" + value + ";" + expires + ";path=/";
        },
        getCookie(name) {
            const decodedCookie = decodeURIComponent(document.cookie);
            const cookies = decodedCookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                let cookie = cookies[i];
                while (cookie.charAt(0) === ' ') {
                    cookie = cookie.substring(1);
                }
                if (cookie.indexOf(name) === 0) {
                    return cookie.substring(name.length + 1, cookie.length);
                }
            }
            return "";
        }
    }
};
