
export default {
    name: 'AppPlatforms',
    props: {
        onPlatformChange: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            platforms: ['iOS', 'Android', 'Web'],
            selectedPlatform: this.$store.state.selectedPlatform,
        }
    },
    mounted() {
        if (this.$refs.glider && this.$refs.buttons) {
            const glider = this.$refs.glider
            const buttons = this.$refs.buttons
            this.updateGliderPosition(glider, buttons);
            window.addEventListener('resize', this.updateGliderPosition(glider, buttons));
        }
    },
    beforeDestroy() {
        if (this.$refs.glider && this.$refs.buttons) {
            const glider = this.$refs.glider
            const buttons = this.$refs.buttons
            window.removeEventListener('resize', this.updateGliderPosition(glider, buttons));
        }
    },
    methods: {
        changePlatform(platform) {
            this.onPlatformChange(platform);
            this.selectedPlatform = platform;
            this.updateGliderPosition(this.$refs.glider, this.$refs.buttons);
            this.$store.commit("setSelectedPlatform", platform.toLowerCase());
        },
        updateGliderPosition(glider, buttons) {
            this.$nextTick(() => {
                const activeButton = Array.from(buttons).find(btn => btn.classList.contains('active'));
                if (activeButton) {
                    const rect = activeButton.getBoundingClientRect();
                    const rectLeft = activeButton.offsetLeft;
                    glider.style.width = `${rect.width}px`;
                    glider.style.transform = `translateX(${rectLeft}px)`;
                }
            });
        },
    },
}
