
export default {
  props: {
    appShot: Object,
    platform_name: String,
    activeItem: Boolean,
    clickFunc: {
      type: Function,
      default: () => { }
    },
    linkClick: {
      type: Function,
      default: () => { }
    }
  },
  data() {
    return {
      screens: [],
      activeIndex: 0
    }
  },
  computed: {
    dummyItemCount() {
      const minItems = 3
      return Math.max(minItems - this.screens.length, 0)
    },
    innerStyle() {
      return {
        transform: `translateX(-${this.activeIndex * 100}%)`,
        transition: this.transitioning ? 'transform 0.5s ease-in-out' : 'none'
      }
    },
    appName() {
      if (typeof this.appShot === 'undefined') {
        return ''
      }
      return String(this.appShot.app_name)
    },
    isPremiumApp() {
      if (typeof this.appShot === 'undefined') {
        return false
      }
      return Boolean(this.appShot.premium_app)
    },
    appCountrySymbol() {
      if (typeof this.appShot === 'undefined') {
        return ''
      }
      return String(this.appShot.country_symbol)
    },
    appDescription() {
      if (typeof this.appShot === 'undefined') {
        return ''
      }
      return String(this.appShot.short_description)
    },
    appIcon() {
      if (typeof this.appShot === 'undefined') {
        return ''
      }
      return String(this.appShot.app_icon)
    },
    getAppShotURL() {
      if (typeof this.appShot === 'undefined') {
        return ''
      }
      const slug = String(this.appShot.slug)
      return `/apps/${slug}/`
    },
    getSlugURL() {
      if (typeof this.appShot === 'undefined') {
        return ''
      }
      const slug = String(this.appShot.slug)
      return slug
    },
    enableAddToCollectionCTA() {
      if (typeof this.appShot === 'undefined') {
        return false
      }

      if (!this.isAuthenticated()) {
        return false
      }

      return true
    }
  },
  watch: {
    appShot: {
      handler: 'updateScreens',
      immediate: true
    }
  },
  created() {
    if (typeof this.appShot === 'undefined') {
      // to check
    }
  },
  methods: {
    moveSlide(direction) {
      this.activeIndex =
        (this.activeIndex + direction + this.screens.length) %
        this.screens.length
    },
    generateAltText(screenData) {
      // Start with the app name
      let altText = `${screenData.app_shot.app_name}`

      // Add platform info
      altText += ` ${screenData.platform.name}`

      // Add screenshot order
      altText += ` app screenshot ${screenData.order}`

      // If there's any OCR text, use a snippet from it
      if (screenData.ocr_text) {
        altText += ` showcasing ${screenData.ocr_text.substring(0, 50)}...` // Taking the first 50 characters for brevity
      }

      return altText
    },
    generateSrcSet(screenData) {
      const srcsetValues = []

      // Check if the resolution URL exists and isn't empty, then append it to the srcsetValues array
      if (
        screenData.resolution_220w &&
        screenData.resolution_220w.trim() !== ''
      ) {
        srcsetValues.push(`${screenData.resolution_220w} 220w`)
      }

      // NOTE: We're not using 440w and 1080w for now.

      // if (screenData.resolution_440w && screenData.resolution_440w.trim() !== "") {
      //     srcsetValues.push(`${screenData.resolution_440w} 440w`);
      // }

      // if (screenData.resolution_1080w && screenData.resolution_1080w.trim() !== "") {
      //     srcsetValues.push(`${screenData.resolution_1080w} 1080w`);
      // }

      // if empty the use img_url
      if (srcsetValues.length === 0) {
        srcsetValues.push(`${screenData.img_url} 1080w`)
      }

      return srcsetValues.join(', ')
    },
    updateScreens() {
      if (typeof this.appShot === 'undefined') {
        this.screens = []
        return
      }
      this.screens = this.appShot.screens
    }
  }
}
