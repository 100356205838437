
import firebase from "firebase/compat/app";

// https://stackoverflow.com/a/54235868
import axios from "axios";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
export default {
  methods: {
    // UI
    canShowEmailPasswordInput() {
      // password_id.hidden = validateEmail(this.email_id.value);
    },
    // Auth
    googleAuth() {
      this.$router.push({ query: { redirection_url: encodeURIComponent(window.location.href) } });
      let context = this;
      var provider = new firebase.auth.GoogleAuthProvider();
      provider.setCustomParameters({
        login_hint: "martin@gmail.com",
      });

      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          /** @type {firebase.auth.OAuthCredential} */
          var user = result.user;
          context.$nuxt.$loading.start()
          context.getIdTokenAndValidate(user);
        })
        .catch((error) => {
          // Handle error
        });
    },
    facebookAuth() {
      console.log("facebookAuth");
    },
    loginWithDribbble() {
      const clientId = process.env.DRIBBBLE_CLIENT_ID
      const redirectUri = encodeURIComponent(process.env.DRIBBBLE_REDIRECT_URI)
      const url = `https://dribbble.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code`
      window.location.href = url
    },
    twitterAuth() {
      this.$nuxt.$loading.start()
      let context = this;
      var provider = new firebase.auth.TwitterAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          var user = result.user;
          context.$nuxt.$loading.start();
          context.getIdTokenAndValidate(user);
        }).catch((error) => {
          console.log(error);
        });
    },
    emailAuth() {
      let email = email_id.value;
      let password = password_id.value;
      // Show toast if invalid
      if (!this.validateEmail(email)) {
        this.showToaster("You have entered an invalid email address!", "error");
        return;
      }

      if (password_id.style.display == "none") {
        password_id.style.display = "block";
        return;
      }

      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((data) => {
          this.proceedToNext(data.user);
        })
        .catch((err) => {
          console.log(err.code);
          if (err.code === "auth/user-not-found") {
            this.emailSignUp();
          }
        });
    },
    getIdTokenAndValidate(user) {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          this.firebaseAuthValidate(idToken, user);
        }.bind(this));
    },
    firebaseAuthValidate(idToken, user) {
      const url = `${process.env.main_API_URL}/firebase_auth_validate/`;
      const bodyFormData = new FormData();
      bodyFormData.append("id_token", idToken);

      // Send referral ID if available
      let referralID = this.$store.state.referralID
      if (referralID && referralID.length > 0) bodyFormData.append("referral_id", referralID)

      axios({
        method: "post",
        url: url,
        data: bodyFormData,
        headers: {
          "Content-Type": `multipart/form-data; boundary=${bodyFormData._boundary}`,
        },
      }).then((response) => {
        const user = response.data;
        this.proceedToNext(user);
      }).catch((error) => {
        // Handle error
      });
    },
    // Validators
    validateEmail(mail) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true;
      }
      return false;
    },
    // Login flow
    proceedToNext(user) {
      this.$store.commit("globalUserDataUpdate", user);
      this.$store.commit("setAuthenticated", true);

      // All data available
      this.openRedirectionUrl();
    },
    // Sign up for new user.
    emailSignUp() {
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.emailId, this.password)
        .then((data) => {
          this.createNewUserRecord(data.user);
        })
        .catch(() => {
          // this.error = err.message;
        });
    },
    async createNewUserRecord(user) {
      var userData = {
        id: user.uid,
        type: "profile",
        username: "",
        displayName: user.displayName,
        email: user.email,
        photo_url: user.photo_url,
        user_symbol: "🎃",
      };

      this.$store.commit("globalUserDataUpdate", userData);
      this.$store.commit("setAuthenticated", true);

      let newProfileKey = this.profiles.push().key;
      await this.profiles.child(newProfileKey).set(userData);

      this.openRedirectionUrl();
    },
    openRedirectionUrl() {
      let redirection_url = this.$route.query.redirection_url;
      if (redirection_url) {
        window.location.href = decodeURIComponent(redirection_url);
      } else {
        window.open("/", "_self");
      }
    },
  },
};
