
export default {
    name: 'MagicSearchTitle',
    data() {
        return {
            emojis: ['🔍', '🔮'],
            currentEmojiIndex: 0,
        }
    },
    computed: {
        currentEmoji() {
            return this.emojis[this.currentEmojiIndex]
        }
    },
    mounted() {
        this.startEmojiAnimation()
    },
    methods: {
        startEmojiAnimation() {
            setInterval(() => {
                this.currentEmojiIndex = (this.currentEmojiIndex + 1) % this.emojis.length
            }, 2000)
        }
    }
}
