
export default {
    name: 'UiScreenComponent',
    props: {
        matchData: {
            type: Object,
            required: true
        },
        clickFunc: {
            type: Function,
            default: () => { }
        }
    },
    data() {
        return {
            screens: [],
            activeIndex: 0,
            windowWidth: window.innerWidth
        }
    },
    computed: {
        getScreen() {
            if (this.matchData && this.matchData.screens && this.matchData.screens.length > 0) {
                return this.matchData.screens[0]
            }
            return {
                resolution_440w: '',
                img_url: '',
            };
        },
        appName() {
            if (typeof this.matchData.app_name === 'undefined') {
                return ''
            }
            return String(this.matchData.app_name)
        },
        isPremiumApp() {
            if (typeof this.matchData === 'undefined') {
                return false
            }
            return Boolean(this.premium_app)
        },
        appDescription() {
            return this.matchData.short_description
        },
        getSlugURL() {
            if (typeof this.matchData === 'undefined') {
                return ''
            }
            const slug = String(this.matchData.appshot_slug)
            return `/apps/${slug}`
        },
    },
    methods: {
        updateScreens() {
            if (typeof this.matchData === 'undefined') {
                this.screens = []
                return
            }
            this.screens = this.matchData.screens
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
            this.$forceUpdate();
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    watch: {
        windowWidth() {
            this.$forceUpdate();
        }
    }
}
