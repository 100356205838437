import { loadStripe } from '@stripe/stripe-js'
import Vue from 'vue'

Vue.mixin({
    methods: {
        async getStripe() {
            // get STRIPE_CLIENT_ID from .env
            let publicKey = process.env.STRIPE_CLIENT_ID
            const stripe = await loadStripe(publicKey)
            return stripe
        }
    }
})