
document.body.addEventListener("keydown", function (event) {
  if (document.body.classList.contains("showScreenPopup")) {
    switch (event.key) {
      case "ArrowLeft":
        event.preventDefault()
        navigateToPrevious();
        break;
      case "ArrowRight":
        event.preventDefault()
        navigateToNext();
        break;
      case "Escape":
        // Escape pressed
        event.preventDefault()
        screenPopupToggle(event);
        break;
      case "s":
      case "S":
        event.preventDefault()
        document.getElementById("share-button-id").click();
        break;
      default:
        console.log(event.key);
    }
  }
});

export default {};
