
export default {
  head: {
    script: [
      { src: "/js/screen-popup.js?v=4" },
      { src: "/js/more-options.js?v=4" },
      { src: "/js/aps-popup.js?v=4" },
      // { src: "/js/options-cms.js?v=4" },
      { src: "/js/share-app.js?v=4" },
      { src: "/js/search-focus.js?v=4" },
      // { src: "/js/app-screen-toggle.js?v=4" },
      // { src: "/js/os-tab-toggle.js?v=4" },
      // { src: "/js/custom-scripts.js?v=4" },
      { src: "/js/collection-tab-toggle.js?v=4" },
      { src: "/js/more-options-collection.js?v=4" },
      // { src: "/js/pricingType.js?v=4" },
    ],
  },
};
